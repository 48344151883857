import { JwtPayload } from "jwt-decode";

export const MODEL_ID_TR2 = 1;
export const MODEL_ID_TR3 = 2;

export interface CustomJwtPayload extends JwtPayload {
  authorities: string[];
}

export interface Link {
  href: string;
}

export interface HateoasObject {
  _links: {
    self: Link;
  };
}

export interface HateoasResponse<T> extends HateoasObject {
  _embedded: T;
}

export interface Page {
  size: number;
  totalElements: number;
  totalPages: number;
  number: number;
}

export interface PagedHateoasResponse<T> extends HateoasResponse<T> {
  page: Page;
}

export type User = HateoasObject & {
  email: string;
};

export type UsersResponse = HateoasResponse<{ users: User[] }>;

export interface KeyValueProperty {
  id: number;
  name: string;
  description?: string;
}

export type StatusesResponse = PagedHateoasResponse<{
  statuses: KeyValueProperty[];
}>;

export type FailureCodesResponse = PagedHateoasResponse<{
  failureCodes: KeyValueProperty[];
}>;

export type LifecycleEventsResponse = PagedHateoasResponse<{
  lifecycleEvents: KeyValueProperty[];
}>;

export type ProductPropertiesResponse = PagedHateoasResponse<{
  productProperties: KeyValueProperty[];
}>;

export interface DeviceApplication {
  id: number;
  device_name: string;
  application_name: string;
  description?: string;
}

export type DeviceApplicationsResponse = PagedHateoasResponse<{
  device_applications: DeviceApplication[];
}>;

export interface ApplicationVersion {
  device_uid: string;
  application: number;
  version: string;
}

export interface LifecycleEvent {
  device_uid: string;
  event: number;
  timestamp: number;
}

export interface BoardProperty {
  device_uid: string;
  property: number;
  value: string;
}

export interface Board extends HateoasObject {
  device_uid: string;
  mac?: string;
  serial?: string;
  pcb_serial?: string;
  battery?: string;
  model: KeyValueProperty;
  probe: string;
  status: KeyValueProperty;
  failure_code?: KeyValueProperty;
  comments?: string;
  self_test_report: string;
  end_of_line_test_report?: string;
  last_updated: number;
  application_versions: ApplicationVersion[];
  lifecycle_events: LifecycleEvent[];
  board_properties: BoardProperty[];
}

export interface NewBoard
  extends Partial<Omit<Board, "status" | "failure_code">> {
  status?: string;
  failure_code?: string;
}

export type BoardsResponse = PagedHateoasResponse<{
  boards: Board[];
}>;
